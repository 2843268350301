import { useCursorStore } from "@/stores/cursorStore";

export function useCustomCursor() {
  const cursorStore = useCursorStore();

  const showCursor = (wording) => {
    cursorStore.showCursor(wording);
  };

  const hideCursor = () => {
    cursorStore.hideCursor();
  };

  return {
    showCursor,
    hideCursor,
  };
}
